<template>
    <div id="pageTable"> 
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>     
        <v-container xl fluid>
            <v-row>
                <div style="display: flex; width:100%; justify-content: flex-start;">
                    <hr class="vr"/>
                    <p class="tblName pb-2">Impuesto sobre la Nómina</p>
                </div>
                <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <v-card style="border-radius: 20px">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-text class="px-30px pt-30px">
                                <v-row class="pa-0 mt-0">
                                    <template v-if="rol == 'root'">
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Cliente"
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                    class="pa-0 ma-0"
                                                    required 
                                                    :error-messages="errors" >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                    </template>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Registro patronal" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Registro patronal"
                                                :items="itemsRegistrosPatronales"
                                                item-text="nombreClave"
                                                item-value="id"
                                                persistent-hint
                                                v-model="registroPatronalModel"
                                                required 
                                                :error-messages="errors" 
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="6" sm="6" md="6" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Año" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Año"
                                                :items="itemsAnios"
                                                item-text="anioEmpresa"
                                                item-value="id"
                                                persistent-hint
                                                v-model="anio_id"
                                                class="pa-0 ma-0"
                                                required 
                                                :error-messages="errors"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="6" sm="6" md="6" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Mes" rules="required">
                                            <v-autocomplete
                                                :items="meses"
                                                item-text="nombre"
                                                item-value="valor"
                                                label="Mes"
                                                hide-details
                                                v-model="mes_selecionado"
                                                outlined
                                                class="ma-0 pa-0"
                                                required 
                                                :error-messages="errors"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0 d-flex">
                                        <v-container fluid class="py-0 d-flex">
                                            <v-checkbox class="pa-0 ma-0 checkboxVuetify" v-model="soloAcumulado" label="Calcular únicamente empleados acumulados"></v-checkbox>
                                            <v-tooltip top class="pb-7">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                </template>
                                                <span class="pb-7 textTooltip">
                                                    En caso de no seleccionar esta opción, solo se <br>
                                                    calcularán los empleados timbrados.
                                                </span>
                                            </v-tooltip>
                                        </v-container>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end pa-30px">
                                <v-btn
                                    class="btnGuardar"
                                    @click="consultar()"
                                    :loading="isLoadingConsultar"
                                    :disabled="invalid" 
                                >
                                    Consultar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
                    <v-card style="border-radius: 20px">
                        <v-overlay :z-index="999" :value="overlayCargar" :absolute="true">
                            <div class="align-center justify-center text-center">
                                <v-progress-circular
                                    indeterminate
                                    :size="100"
                                    :width="7"
                                    color="#2DB9D1"
                                >
                                    <v-img
                                        src="/static/icon/favicon.ico"
                                        :width="60"
                                    ></v-img>
                                </v-progress-circular>
                                <p class="mt-5">Cargando...</p>
                            </div>
                        </v-overlay>
                        <v-card-text class="px-30px pt-30px">
                            <v-row class="pa-0 ma-0 informacionGeneral">
                                <v-row class="px-9 pt-12 pb-7">
                                    <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                        <p class="informacionGeneralTitulo">Total general de complementos</p>
                                    </v-col> 
                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0" :style="$vuetify.breakpoint.name != 'xs'? 'border-right:1px solid #EFF1FB;':''">
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <p class="mb-0 informacionGeneralSubtitle">Estado:</p>
                                            <p class="informacionGeneralText">{{informacionISN.estado ? informacionISN.estado:"Información faltante*"}}</p>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <p class="mb-0 informacionGeneralSubtitle">Total de erogaciones al personal subordinado:</p>
                                            <p class="informacionGeneralText">{{formatNum(informacionISN.total_erogaciones_subordinado)}}</p>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <p class="mb-0 informacionGeneralSubtitle">Total de erogaciones asimilables a salario:</p>
                                            <p class="informacionGeneralText">{{formatNum(informacionISN.total_erogaciones_asimilado)}}</p>
                                        </v-col>
                                    </v-col>

                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0" :style="$vuetify.breakpoint.name != 'xs'? 'border-right:1px solid #EFF1FB;':''">
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <p class="mb-0 informacionGeneralSubtitle">Total base:</p>
                                            <p class="informacionGeneralText">{{formatNum(informacionISN.total_base)}}</p>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <p class="mb-0 informacionGeneralSubtitle">Tasa de impuesto:</p>
                                            <p class="informacionGeneralText">{{informacionISN.tasa_impuesto}} %</p>
                                        </v-col>
                                        
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <p class="mb-0 informacionGeneralSubtitle">Impuesto de tasa:</p>
                                            <p class="informacionGeneralText">${{informacionISN.impuesto_de_tasa}}</p>
                                        </v-col>
                                    </v-col>

                                    <v-col cols="12" xs="4" sm="4" md="4" class="py-0">
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <p class="mb-0 informacionGeneralSubtitle">Impuesto de tabla:</p>
                                            <p class="informacionGeneralText">{{formatNum(informacionISN.impuesto_tabla)}}</p>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <p class="mb-0 informacionGeneralSubtitle">Impuesto adicional:</p>
                                            <p class="informacionGeneralText">{{formatNum(informacionISN.impuesto_adicional)}}</p>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                            <p class="mb-0 informacionGeneralSubtitle">Impuesto a pagar:</p>
                                            <p class="informacionGeneralText">{{formatNum(informacionISN.impuesto_pagar)}}</p>
                                        </v-col>
                                    </v-col>
                                </v-row>
                                <v-row class="px-9 pt-0 pb-7 d-flex justify-space-between">
                                    <v-col cols="12" xs="12" sm="12" md="12"  class="py-0">
                                        <p class="informacionGeneralTitulo">Información de trabajadores en el periodo</p>
                                    </v-col> 
                                    <v-col cols="10" xs="10" sm="2" md="2" class="py-0">
                                        <p class="mb-0 informacionGeneralSubtitle">No. al inicio:</p>
                                        <p class="informacionGeneralText">{{informacionISN.trabajadores_periodo.total_inicio}}</p>
                                    </v-col>
                                    <v-col cols="10" xs="10" sm="2" md="2" class="py-0">
                                        <p class="mb-0 informacionGeneralSubtitle">No. de altas:</p>
                                        <p class="informacionGeneralText">{{informacionISN.trabajadores_periodo.total_altas}}</p>
                                    </v-col>
                                    <v-col cols="10" xs="10" sm="2" md="2" class="py-0">
                                        <p class="mb-0 informacionGeneralSubtitle">No. de bajas:</p>
                                        <p class="informacionGeneralText">{{informacionISN.trabajadores_periodo.total_bajas}}</p>
                                    </v-col>
                                    <v-col cols="10" xs="10" sm="2" md="2" class="py-0">
                                        <p class="mb-0 informacionGeneralSubtitle">No. al final:</p>
                                        <p class="informacionGeneralText">{{informacionISN.trabajadores_periodo.total_final ? informacionISN.trabajadores_periodo.total_final:0}} </p>
                                    </v-col>
                                    <v-col cols="10" xs="10" sm="2" md="2" class="py-0">
                                        <p class="mb-0 informacionGeneralSubtitle">No. total asimilados:</p>
                                        <p class="informacionGeneralText">{{informacionISN.trabajadores_periodo.total_asimilables}}</p>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-card-text>
                        <v-card-text class="pa-30px" style="position:relative;">
                            
                            <v-row class="pa-0 ma-0 areaConsulta informacionGeneral" style="">
                                <v-container>
                                    <v-col 
                                        cols="12" xs="12" sm="12" md="12" 
                                        class="pa-0 " 
                                        :class="showDatos ? '':'d-flex justify-center align-center text-center'"
                                        :style="showDatos ? `background: #FFF; min-height:500px;`:`background: #F7F7F7; min-height:500px; font-weight: 400; font-style: normal;font-size: 14px; line-height: 17px;` "
                                        
                                    >
                                        <v-btn
                                            color="#FFF"
                                            class="elevation-0 mr-13 mt-13 pt-0 pb-0 pl-0 pr-0 btn-posicion"
                                            @click="reporte()"
                                            height="52"
                                            width="20"
                                            style="border-radius: 15px; box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);"
                                            :disabled="!showDatos"
                                        >
                                            <img src="/static/icon/xls.png" style="width:30px"/>
                                        </v-btn>
                                        <p v-if="!showDatos">No hay elementos para mostrar</p>
                                        <div v-else>
                                            <template >
                                                <tr>
                                                    <td class="pb-2 pt-1" style="color:#444975; font-weight:600; font-size:14px;">Percepciones:</td>
                                                    <td class="pb-2 pt-1 pl-10" style="color:#444975; font-weight:600; font-size:14px;">Base de impuesto</td>
                                                </tr>
                                                <tr v-for="item,index in informacionISN.datosSubordinados" :key="index">
                                                    <td><p class="subtitle">{{item.concepto}}</p></td>
                                                    <td class="pl-10"><p class="text">{{formatNum(item.total)}}</p></td>
                                                </tr>
                                            </template>
                                        </div>
                                        
                                    </v-col>
                                </v-container>
                               
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import apiClientes from "@/api/clientes";
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import apiISN from "@/api/nominas/isn";
import Notify from '@/plugins/notify';

export default {
    data() {
        return {
            datosLogin                  : null,
            rol                         : null,
            clientes                    : [],
            cliente_value               : null,
            registroPatronalModel       : null,
            itemsRegistrosPatronales    : [],
            itemsAnios                  : [],
            anio_id                     : null,
            mes_selecionado             : null,
            meses: [
                { id: 0,    nombre: "Enero",        valor: "Enero" },
                { id: 1,    nombre: "Febrero",      valor: "Febrero" },
                { id: 2,    nombre: "Marzo",        valor: "Marzo" },
                { id: 3,    nombre: "Abril",        valor: "Abril" },
                { id: 4,    nombre: "Mayo",         valor: "Mayo" },
                { id: 5,    nombre: "Junio",        valor: "Junio" },
                { id: 6,    nombre: "Julio",        valor: "Julio" },
                { id: 7,    nombre: "Agosto",       valor: "Agosto" },
                { id: 8,    nombre: "Septiembre",   valor: "Septiembre" },
                { id: 9,    nombre: "Octubre",      valor: "Octubre" },
                { id: 10,   nombre: "Noviembre",    valor: "Noviembre" },
                { id: 11,   nombre: "Diciembre",    valor: "Diciembre" },
            ],
            loadDoc: false,
            fileURL : null,
            informacionISN : {
                estado: null,
                total_erogaciones_asimilado : 0,
                impuesto_tabla   : 0,
                total_erogaciones_subordinado : 0,
                total_base: 0,
                impuesto_adicional : 0,
                tasa_impuesto : 0,
                impuesto_pagar : 0,
                impuesto_de_tasa : 0,
                trabajadores_periodo: {
                    total_inicio: 0,
                    total_altas: 0,
                    total_bajas: 0,
                    total_asimilables: 0
                },

                datosSubordinados: [],
                datosAsimilados : [],
            },
            isLoadingConsultar  : false,
            showDatos: false,
            overlayCargar: false,
            loadingLottie: false,
            fraseLoading : "",
            soloAcumulado: false,

        }
    },
    watch: {
        cliente_value: function(val){
            this.resetValoresISN()
            if(val!= null){
                this.getRegistroPatronal(val);
            }
        },
        registroPatronalModel:function(val){
            this.resetValoresISN()
            if(val!=null){
                this.getAnios();
            }
        },
        anio_id:function(val){
            this.resetValoresISN()
        },
        mes_selecionado:function(val){
            this.resetValoresISN()
        }   
    },
    methods: {
        resetValoresISN(){
            this.showDatos = false;
            this.informacionISN = {
                estado: null,
                total_erogaciones_asimilado : 0,
                impuesto_tabla   : 0,
                total_erogaciones_subordinado : 0,
                total_base: 0,
                impuesto_adicional : 0,
                tasa_impuesto : 0,
                impuesto_pagar : 0,
                impuesto_de_tasa : 0,
                trabajadores_periodo: {
                    total_inicio: 0,
                    total_altas: 0,
                    total_bajas: 0,
                    total_asimilables: 0
                },

                datosSubordinados: [],
                datosAsimilados : [],
                soloAcumulado: false,
            };
        },
        /**
         * @method getClientes Este método realiza petición a la API de clientes.
         * @description Realiza petición a la API de clientes cuando el rol es root y el resultado lo almacena en el array clientes[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        getClientes(cliente = null) {
            let param = { activo: true, paginate: false };
            apiClientes.getClientes(param).then((response) => {
                this.clientes = response.data;
            })
            .catch((error) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },

        /**
         * @method getRegistroPatronal Este método realiza petición a la API de registros patronales.
         * @description Realiza petición a la API de registros patronales cuando el valor de cliente es asigando y el resultado lo almacena en el array itemsRegistrosPatronales[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        async getRegistroPatronal(){
            this.itemsRegistrosPatronales = [];
            let parametros = { activo:true, paginate:false, order:"asc", cliente_id: this.cliente_value }
            await apiRegistroPatronal.find(parametros).then((response) => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.nombreClave = item.nombre + ' - ' + item.clave
                        this.itemsRegistrosPatronales.push(item);
                    })
                }
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Registros Patronales.");
            });
        },

        /**
         * @method getAnios Este método realiza petición a la API de años fiscales.
         * @description Realiza petición a la API de años fiscales cuando el valor del registro patronal es asigando y el resultado lo almacena en el array itemsAnios[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        async getAnios(){
            this.itemsAnios = [];
            let parametros = { activo:true, paginate:false, order:"desc", registro_patronal_id: this.registroPatronalModel }
            await apiISN.findAnios(parametros).then((response) => {
                response.data.forEach(item => {
                    item.anioEmpresa = item.anio.nombre +' '+(item.empresa?item.empresa.nombre:'')
                })
                this.itemsAnios = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los años.");
            });
        },
        abrirModalPDF(item){
            self = this;
            const axios = require("axios");
            let url = "incidencias/download";
            //let parametros = { url_archivo: item.url };
            let parametros = { url_archivo: "/documentos/C1-E14-S15-D21-EM48/11630958227_documento.xlsx" };
            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then(function(response) {
                    self.fileURL = URL.createObjectURL(response.data);
                    /* $('#myIframe').attr('src', fileURL); 
                    $('iframe').on("load", function () {
                        $(this).contents().find('img').css({width: '100%'});
                    }); */
                });
            } catch (error) {
                console.log(error);
            }
            
        },
        downExcel() {
            let iframe = window.frames['downExcel'];
            let href = '';//Interface path address, return data type is application/binary, background control display information, front end only download function


            self = this;
            const axios = require("axios");
            let url = "incidencias/download";
            //let parametros = { url_archivo: item.url };
            let parametros = { url_archivo: "/documentos/C1-E14-S15-D21-EM48/11630958227_documento.xlsx" };

                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then(function(response) {
                    href = URL.createObjectURL(response.data);
                    
                })
                .catch(err => {
                    console.log(err);
                });

                iframe.location.href = href
                self.loadDoc = true;
        },

        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(num){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },

        /**
         * @method consultar Esta método realiza la petición para realizar las consultas del ISN.
         * @description Esta método realiza la petición para realizar las consultas del ISN y lo muestra en el navegador.
        */
        consultar(){
            this.$refs.form.validate().then(success => {
                this.isLoadingConsultar = true;
                this.overlayCargar = true;
                if(!success) {
                    this.isLoadingConsultar = false;
                    return;
                }

                let parametros = { 
                    registro_patronal_id            : this.registroPatronalModel, 
                    anio_fiscal_id                  : this.anio_id, 
                    mes_acumulacion                 : this.mes_selecionado,
                    tipo_reporte                    : 'json',
                    calcular_empleados_acumulados   : this.soloAcumulado
                }
                apiISN.consultarISN(parametros).then((response) => {
                    this.informacionISN = response;
                    this.showDatos = true;
                    this.isLoadingConsultar = false;
                    this.overlayCargar = false;
                })
                .catch(err => {
                    this.isLoadingConsultar = false;
                    this.overlayCargar = false;
                    console.log(err);
                    Notify.ErrorToast("Ocurrió un error al cargar los datos.");
                });
            });
        },
        reporte(tipo = 'xlsx'){
            if( this.registroPatronalModel == null || this.registroPatronalModel == "" ){
                Notify.ErrorToast("Selecciona un Registro patronal");
                return;
            }
            if( this.mes_selecionado == null || this.mes_selecionado == "" ){
                Notify.ErrorToast("Selecciona un mes");
                return;
            }
            if( this.anio_id == null || this.anio_id == "" ){
                Notify.ErrorToast("Selecciona un año fiscal");
                return;
            }

            let self = this;
            this.loadingLottie =  true;
            this.fraseLoading = "Descargando";
            const FileDownload = require("js-file-download");

            let parametros = { 
                registro_patronal_id            : this.registroPatronalModel, 
                anio_fiscal_id                  : this.anio_id, 
                mes_acumulacion                 : this.mes_selecionado,
                tipo_reporte                    : tipo,
                calcular_empleados_acumulados   : this.soloAcumulado
            }

            let nombre;
            nombre = "ReporteISN."+tipo;

            let url = "reportes/impuesto-sobre-nomina";
            try {
                Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                })
                .catch(err =>{
                    console.log(err);
                    this.loadingLottie = false;
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar la petición");
                console.log(err);
                this.loadingLottie = false;
            }
        },
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;

        if (this.rol == "root") {
            this.getClientes();
        }
        else{
            this.cliente_value = this.datosLogin.cliente_id;
        }
        
    },    
}
</script>

<style scoped>
    .informacionGeneral{
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 15px;
    }
    .informacionGeneralTitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2144;
    }
    .informacionGeneralSubtitle{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #828282;
    }
    .informacionGeneralText{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #4F4F4F;
    }
    .areaConsulta{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 37px;
        color: #828282;
    }
    .btnClass{
        width: 200px;
        background-color: cornflowerblue;
        height: 60px;
        border: none;
        color: #ffffff;
        font-size:30px;
        cursor:pointer
    }
    
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #828282;
        margin-bottom:0px;
    }
    .btn-posicion{
        position: absolute;
        top:16px;
        right: 16px;
    }
</style>